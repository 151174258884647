<template>
  
  <!-- <HeaderVue @togle-profile-modal="toggleProfileModal()" :isAuthenticated="isAuthenticated"></HeaderVue>
    <main> -->
      <component :is="layout">
          <router-view></router-view>
      </component>
    <!-- </main>
    <FooterVue></FooterVue>
    <ModalProfileAction v-show="isOpenedProfileModal" @togle-profile-modal="toggleProfileModal()"/>
    <notifications /> -->
</template>

<script>
  import LogsignLayout from '@/layouts/LogsignLayout'
  import MainLayout from '@/layouts/MainLayout'
  import UserLayout from '@/layouts/UserLayout'
  import ConstructorLayout from '@/layouts/ConstructorLayout'
  import AdminLayout from '@/layouts/AdminLayout'

    import HeaderVue from '@/components/common/Header.vue'
    import FooterVue from '@/components/common/Footer.vue'
    import ModalProfileAction from '@/components/common/ModalProfileAction.vue'

  export default {
computed: {
  layout () {
return (this.$route.meta.layout) + '-layout'
  }
},
components: {
  LogsignLayout,
  MainLayout,
  UserLayout,
  ConstructorLayout,
  AdminLayout,
  
  HeaderVue,
  FooterVue,
  ModalProfileAction,
  },
  data() {
    return {
        baseBackendUrl: process.env.VUE_APP_BASE_URL,
        isOpenedProfileModal: false,
        isAuthenticated: false,
    }
  },
  methods: {
    toggleProfileModal() {
        this.isOpenedProfileModal = !this.isOpenedProfileModal
    },
    mountedAdminTagManager() {
      var script = document.createElement("script");
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=false;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T2SGBK7');`;
      document.getElementsByTagName('body')[0].append(script);

      var noscript = document.createElement("noscript");
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T2SGBK7"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.getElementsByTagName('body')[0].append(noscript);
      
      var newSrcScript = document.createElement("script");
      newSrcScript.async = true
      newSrcScript.src = "https://www.googletagmanager.com/gtag/js?id=AW-11480613228"
      document.getElementsByTagName('body')[0].append(newSrcScript);

      var newScript = document.createElement("script");
      newScript.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-11480613228');`;
      document.getElementsByTagName('body')[0].append(newScript);
    },
  },
  mounted() {
      // if (this.baseBackendUrl != "https://pre.adsquiz.io") {
        this.mountedAdminTagManager()
      // }
  }
  
  }
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&family=Source+Code+Pro&display=swap');

:root {
    --color-main-white: #FBFBFB;
    --color-main-light-gray: #F5F5F5;
    --color-main-gray: #D9D9D9;
    --color-main-dark-gray: #808080;
    --color-main-black: #1C1C1C;
    --color-main-light-blue: #E6E8F1;
    --color-main-selected-blue: #F3F4F9;
    --color-main-blue: #3749C0;
    --color-additional-red: #B22222;
    --color-additional-green: #64B84F;
    --color-additional-orange: #ED804C;

    --theme-color-elem-bg-1: #324ac7;
    --theme-color-elem-bg-2: #9d324c;
    --theme-color-elem-bg-3: #463cb5;
    --theme-color-elem-bg-4: #1c8f6a;
    --theme-color-elem-bg-5: #eff5fa;
    --theme-color-elem-bg-6: #6f21d8;
    --theme-color-elem-bg-7: #5f9b8c;
    --theme-color-elem-bg-8: #5f9b8c;
    --theme-color-elem-bg-9: #de446a;
    --theme-color-elem-bg-10: #4d4793;
    --theme-color-elem-bg-11: #095eac;
    --theme-color-elem-bg-12: #1e2029;
    --theme-color-elem-bg-13: #2f3e4c;
    --theme-color-elem-bg-14: #000;
    --theme-color-elem-bg-15: #163440;
    --theme-color-elem-bg-16: #6056a0;
    --theme-color-elem-bg-17: #1e2029;
    --theme-color-text-on-bg-5: #1e2029;


    --box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0,12);
    --border-default: 1px solid var(--color-main-light-blue);
    --border-gray: 1px solid var(--color-gray);
    --border-blue: 1px solid var(--color-main-blue);
}

@mixin text-h1 {
  font-size: 32px;
  line-height: 1.17em;
  font-weight: 700;
}
@mixin text-h2 {
  font-size: 28px;
  line-height: 1.25em;
  font-weight: 700;
}
@mixin text-h3 {
  font-size: 24px;
  line-height: 1.33em;
  font-weight: 700;
}
@mixin text-h4 {
  font-size: 18px;
  line-height: 1.33em;
  font-weight: 700;
}
@mixin text-h5 {
  font-size: 14px;
  line-height: 1.4em;
  font-weight: 800;
}
@mixin text-h6 {
  font-size: 14px;
  line-height: 1.3em;
  font-weight: 400;
}

@media screen and (max-width: 920px) {
  @mixin text-h1 {
    font-size: 38px;
    line-height: 1.17em;
    font-weight: 700;
  }
  @mixin text-h2 {
    font-size: 28px;
    line-height: 1.25em;
    font-weight: 700;
  }
  @mixin text-h3 {
    font-size: 22px;
    line-height: 1.33em;
    font-weight: 700;
  }
}
html {
  height: 100%;
}
body {
  height: 100%;
}
html {
  height: 100%;
}
[data-v-app] {
  height: 100%;
}
h1 {
  @include text-h1;
}
h2 {
  @include text-h2;
}
h3 {
  @include text-h3;
}
h4 {
  @include text-h4;
}
h5 {
  @include text-h5;
}
h6 {
  @include text-h6;
}

@mixin text-body1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
@mixin text-body2 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}
@mixin text-body3 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}
@mixin text-body4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
@mixin text-body5 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
}
@mixin text-body6 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
@mixin text-body7 {
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
}

@mixin default_input {
  border-radius: 8px;
  padding: 14px 24px;
  border: var(--border-default);
  background: var(--color-main-white);
}
@mixin default_input_with_right_img {
  border-radius: 8px;
  padding: 14px 40px 12px 16px;
  border: var(--border-default);
  background: var(--color-main-white);
}
@mixin default_textarea {
  border: var(--border-default);
  background: var(--color-main-white);
  height: 88px;
}

@mixin label_text_on {
  @include text-body5;
  color: var(--color-main-black);
}
@mixin label_text_off {
  @include text-body5;
  color: var(--color-main-dark-gray);
}
@mixin pre {
  border: var(--border-default);
  background: var(--color-main-white);
  color: var(--color-main-black);
  font-family: 'Source Code Pro', monospace;
  white-space: pre-wrap;
  font-size: 12px;
  margin: 0;
  border-radius: 8px;
  padding: 14px 8px;
  width: 100%;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}
.fast-fade-enter-active, .fast-fade-leave-active {
  transition: opacity 0.1s ease;
}
.fade-enter, .fade-enter-from, .fade-leave-to,
.fast-fade-enter, .fast-fade-enter-from, .fast-fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from, .fade-leave,
.fast-fade-enter-to, .fast-fade-leave-from, .fast-fade-leave {
  opacity: 1;
}

.height-accordeon-enter-active {
  transition: all 0.5s ease;
}
.height-accordeon-leave-active {
  transition: all 0.1s ease-in;
}
.height-accordeon-enter, .height-accordeon-enter-from, .height-accordeon-leave-to {
  // transform: scale(1, 0);
  max-height: 0;
  height: 0;
  opacity: 0;
}
.height-accordeon-enter-to, .height-accordeon-leave-from, .height-accordeon-leave {
  // transform: scale(1, 1);
  max-height: 2000px;
  opacity: 1;
}

.height-tarif-period-select-enter-active {
  transition: all 0.5s ease;
}
.height-tarif-period-select-leave-active {
  transition: all 0.3s ease-in;
}
.height-tarif-period-select-enter, .height-tarif-period-select-enter-from, .height-tarif-period-select-leave-to {
  max-height: 0px;
  height: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
.height-tarif-period-select-enter-to, .height-tarif-period-select-leave-from, .height-tarif-period-select-leave {
  max-height: 100px;
  height: 80px;
  margin-top: 24px;
  margin-bottom: 38px;
  opacity: 1;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}

// .fade-enter-active {
//   animation: fade-in 0.3s;
// }
// .fade-leave-active {
//   animation: fade-in 0.3s reverse;
// }
// .bounce-fade-enter-active {
//   animation: bounce-in 0.3s;
// }
// .bounce-fade-leave-active {
//   animation: bounce-in 0.3s reverse;
// }

// @keyframes fade-in {
//   0% {
//     opacity: 0;
//   }
//   25% {
//     opacity: 0.5;
//   }
//   50% {
//     // opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// @keyframes bounce-in {
//   0% {
//     opacity: 0;
//     transform: scale(0.5);
//   }
//   // 25% {
//   //   opacity: 0;
//   // }
//   50% {
//     transform: scale(1.1);
//   }
//   100% {
//     opacity: 1;
//     transform: scale(1);
//   }
// }

.blue-text {
    color: var(--color-main-blue);
    font-size: inherit;
}

.btn-default-blue {
    @include text-body3;
    cursor: pointer;
    text-align: center;
    background: var(--color-main-blue);
    border: 1px solid var(--color-main-blue);
    color: var(--color-main-white);
    border-radius: 8px;
    padding: 12px 16px;
    width: 100%;
    height: 40px;
    line-height: 1em;
    // box-shadow: 0 4px 12px 0 rgba(55, 73, 192, 0.2);
}

.btn-default-blue:hover {
    box-shadow: 0 4px 12px 0 rgba(55, 73, 192, 0.2);
}

.btn-default-blue:disabled,
.btn-default-blue.disabled {
    background: var(--color-main-light-gray);
    border: 1px solid var(--color-main-gray);
    color: var(--color-main-dark-gray);
}

.btn-default-white {
    @include text-body3;
    cursor: pointer;
    text-align: center;
    background-color: var(--color-main-white);
    border: 1px solid var(--color-main-black);
    color: var(--color-main-black);
    border-radius: 8px;
    padding: 12px 16px;
    width: 100%;
    height: 40px;
    line-height: 1em;
}

.btn-default-white:hover {
    box-shadow: 0 2px 8px 0 rgba(28, 28, 28, 0.16);
}

.btn-default-white:disabled,
.btn-default-white.disabled {
    border: 1px solid var(--color-main-gray);
    color: var(--color-main-dark-gray);
}

.btn-white-blue {
    @include text-body3;
    cursor: pointer;
    text-align: center;
    background-color: var(--color-main-white);
    border: 1px solid var(--color-main-blue);
    color: var(--color-main-blue);
    border-radius: 8px;
    padding: 12px 16px;
    width: 100%;
    height: 40px;
    line-height: 1em;
}

.btn-white-blue:hover {
    box-shadow: 0 2px 8px 0 rgba(28, 28, 28, 0.16);
}

.btn-white-blue:disabled,
.btn-white-blue.disabled {
    background: var(--color-main-light-gray);
    border: 1px solid var(--color-main-gray);
    color: var(--color-main-dark-gray);
}

.btn-default-google {
    @include text-body3;
    cursor: pointer;
    text-align: center;
    background: var(--color-main-white);
    border: 1px solid var(--color-main-gray);
    color: var(--color-main-black);
    border-radius: 8px;
    padding: 12px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-default-google>img,
.btn-default-black>img,
.btn-default-blue>img,
.btn-default-white>img,
.btn-white-blue>img,
.btn-default-selected-blue>img,
.btn-default-selected-blue-border>img,
.btn-default-google>svg,
.btn-default-black>svg,
.btn-default-blue>svg,
.btn-default-white>svg,
.btn-white-blue>svg,
.btn-default-selected-blue>svg,
.btn-default-selected-blue-border>svg
   {
    width: 14px;
    height: 14px;
    margin-right: 8px;
}

.btn-default-google:hover {
    box-shadow: 0 2px 6px 0 rgba(28, 28, 28, 0.12);
}

.btn-default-black {
    @include text-body3;
    cursor: pointer;
    text-align: center;
    background: var(--color-main-white);
    border: 1px solid var(--color-main-black);
    color: var(--color-main-black);
    border-radius: 8px;
    padding: 12px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    line-height: 1em;
}

.btn-default-black:hover {
    box-shadow: 0 2px 6px 0 rgba(28, 28, 28, 0.12);
}

.btn-default-white-red {
    @include text-body3;
    cursor: pointer;
    text-align: center;
    background: var(--color-main-white);
    border: 1px solid var(--color-additional-red);
    color: var(--color-additional-red);
    border-radius: 8px;
    padding: 12px 16px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    line-height: 1em;
}

.btn-default-white-red:hover {
    box-shadow: 0 2px 6px 0 rgba(28, 28, 28, 0.12);
}


.btn-default-white-red:disabled {
  background: var(--color-main-light-gray);
  border: 1px solid var(--color-main-gray);
  color: var(--color-main-dark-gray);
}

.btn-default-selected-blue {
    @include text-body3;
    cursor: pointer;
    text-align: center;
    background: var(--color-main-selected-blue);
    border: 1px solid var(--color-main-selected-blue);
    color: var(--color-main-blue);
    border-radius: 8px;
    padding: 8px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    line-height: 1em;
}

.btn-default-selected-blue img {
    width: 16px;
    height: 16px;
    margin-right: 12px;
}

.btn-default-selected-blue:hover {
    cursor: pointer;
}

.btn-default-selected-blue-border {
  @include text-body3;
  cursor: pointer;
  text-align: center;
  background: var(--color-main-selected-blue);
  border: 1px solid var(--color-main-blue);
  color: var(--color-main-blue);
  border-radius: 8px;
  padding: 8px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  line-height: 1em;
}

.btn-default-selected-blue-border:hover {
  cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(55, 73, 192, 0.2);
}

.btn-default-square {
    @include text-body3;
    cursor: pointer;
    text-align: center;
    background: var(--color-main-white);
    border: var(--border-default);
    color: var(--color-main-black);
    border-radius: 8px;
    padding: 8px;
    width: 40px;
    height: 40px;
    line-height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-default-square svg {
    width: 100%;
    height: 100%;
}

.btn-default-half {
  width: calc(50% - 12px);
}

.input_default_wrap {
    margin-bottom: 24px;
    width: 100%;
}

.input_default_wrap_full {
  width: 100%;
}

.input_default_wrap_half {
  width: calc(50% - 12px);
}

.input_default_label {
  @include text-body5;
  width: 100%;
  color: var(--color-main-black);
  margin-bottom: 4px;
}

.input_default {
    width: 100%;
    padding: 14px 24px;
    background: var(--color-main-white);
    border: 1px solid var(--color-main-gray);
    color: var(--color-main-black);
    border-radius: 8px;
    text-align: left;
    outline: none;
}

.input_default::placeholder {
    color: var(--color-main-dark-gray);
    text-align: left;
}

.input_default:active {
    outline: none;
}

.input_default:hover {
    cursor: text;
}

.input_default_wrap.disabled .input_default,
.input_default:disabled,
.input_default.disabled {
    background: var(--color-main-light-gray);
    border: 1px solid var(--color-main-gray);
    color: var(--color-main-dark-gray);
}

.input_default_wrap.disabled .input_default_label {
  color: var(--color-main-dark-gray);
}

.input_default_wrap.wrong .input_default_label {
  color: var(--color-additional-red);
}

.input_default_wrap.wrong .input_default {
  border: 1px solid var(--color-additional-red);
}

.select_default_label {
  @include text-body5;
  display: block;
  width: 100%;
  color: var(--color-main-black);
  margin-bottom: 4px;
}

.select_default {
    width: 100%;
    padding: 14px 48px 14px 20px;
    background: var(--color-main-white);
    border: 1px solid var(--color-main-gray);
    color: var(--color-main-black);
    border-radius: 8px;
    text-align: left;
    outline: none;
}

.select_default::placeholder {
    color: var(--color-main-dark-gray);
    text-align: left;
}

.select_default:active {
    outline: none;
}

.default_checkbox_wrap input {
  display: none;
}

.default_checkbox_wrap label.default_checkbox_text {
  @include text-body5;
}

.default_checkbox_wrap label.default_checkbox_text a {
  @include text-body6;
  padding: 0 4px;
}

.subscription_tarif_checkbox {
  @include text-body6;
  color: var(--color-main-black);
}

.default_checkbox_text .checkbox_mark {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  border: 1px solid var(--color-main-dark-gray);
  background-color: #fff;
  border-radius: 3px;
  margin-right: 8px;
}
.default_checkbox_text:hover input~.checkbox_mark {
  background-color: var(--color-main-selected-blue);
}
.default_checkbox_text input:checked ~ .checkbox_mark {
  background-color: var(--color-main-blue);
  border-color: var(--color-main-blue);
}
.default_checkbox_wrap label.default_checkbox_text a {
  color: var(--color-main-blue);
}
.default_checkbox_text input:checked~.checkbox_mark:after {
    display: block;
}
.default_checkbox_text .checkbox_mark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border-radius: 1px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.checkbox_mark:after {
    content: "";
    position: absolute;
    display: none;
}

.back-to-work-board,
.back-to-settings {
  @include text-body5;
  color: var(--color-main-black);
  border: none;
  background-color: transparent;
  position: relative;
  padding: 8px 4px 8px 44px;
  background-image: url(../public/img/icons/back_to_workboard.svg);
  background-position: 0 center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

@media screen and (max-width: 760px) {
  .constructor-board_back_to_workboard_wrap .back-to-work-board {
    background-size: 24px;
    padding: 8px 4px 8px 28px;
  }
  .user-board_back_to_workboard_wrap .back-to-work-board {
    background-size: 24px;
    padding: 8px 4px 8px 28px;
  }
}

.header_upversion:hover {
    box-shadow: 0 4px 12px 0 rgba(55, 73, 192, 0.2);
}

.leads_left_counter {
  @include text-body4;
    color: var(--color-main-dark-gray);
    background: var(--color-main-light-gray);
    border: 1px solid var(--color-main-light-gray);
}
.leads_left_counter svg path {
  fill: var(--color-main-dark-gray);
}

.leads_left_counter.orange_modificator {
    color: var(--color-additional-orange);
    background: var(--color-main-white);
    border-color: var(--color-additional-orange);
}
.leads_left_counter.orange_modificator svg path {
  fill: var(--color-additional-orange);
}

.leads_left_counter.red_modificator {
    color: var(--color-additional-red);
    background: var(--color-main-white);
    border-color: var(--color-additional-red);
}
.leads_left_counter.red_modificator svg path {
  fill: var(--color-additional-red);
}

.leads_left_counter.blue_modificator {
    color: var(--color-main-blue);
    background: var(--color-main-selected-blue);
    border-color: var(--color-main-blue);
}
.leads_left_counter.blue_modificator svg path {
  fill: var(--color-main-blue);
}

.payment_plan_limit_lemniscate {
  font-size: 28px!important;
}













.forgot_pass_link {
    @include text-body5;
    margin-top: 8px;
    color: var(--color-additional-red);
}

.agreement_label p {
    @include text-body6;
    color: var(--color-main-dark-gray);
    margin-left: 10px;
}
.agreement_label a {
    @include text-body5;
    font-size: inherit;
    margin: 0 5px;
    display: inline;
    color: var(--color-main-blue);
}

.switch_logsign_wrap  p {
    @include text-body4;
    color: var(--color-main-dark-gray);
    margin-right: 4px;
}

.switch_logsign_wrap a {
    @include text-body3;
    color: var(--color-main-blue);
}

.reset-password-tip {
  @include text-body4;
  color: var(--color-main-dark-gray);
  margin-top: auto;
}


.profile-modal-menu a,
.profile-modal-menu input {
  @include text-body4;
}






  
.quiz-slot {
  background: var(--color-main-white);
  border: var(--border-default);
}

.quiz-slot_handler {
  background: var(--color-main-light-gray);
  border-right: var(--border-default);
}

.quiz-slot_body {
  background: var(--color-main-white);
}

.quiz-slot_date {
  color: var(--color-main-dark-gray);
  margin-bottom: 4px;
  @include text-body6;
}

.quiz-slot_title {
  color: var(--color-main-black);
  @include text-h4;
}

.quiz-slot_footer {
  background: var(--color-main-white);
  border-top: var(--border-default);
}

.template_search_input {
  border: var(--border-default);
}

.templates_tags_wrap label {
    background-color: var(--color-main-white);
    border: var(--border-default);
    color: var(--color-main-black);
}

.templates_tags_wrap input:checked+label {
    border: var(--border-gray);
    color: var(--color-main-blue);
}

.qb_body {
  background-color: var(--color-main-white);
  border: var(--border-default);
}

.quiz_tags span {
  background-color: var(--color-main-white);
  border: var(--border-default);
  color: var(--color-main-black);
}

// --------------------------



.constructor_control,
.user_control {
    background-color: var(--color-main-white);
    border-right: var(--border-default);
}

.c-ctrl_q-name {
  @include default_input_with_right_img;
  color: var(--color-main-black);
}

.c-ctrl_main-link nav {
    background: var(--color-main-light-gray);
}

.c-ctrl_main-link nav a {
    background: transparent;
    color: var(--color-main-dark-gray);
}

.c-ctrl_main-link nav a.active {
    background: var(--color-main-blue);
    color: var(--color-main-white);
}


.accordion-item {
    border-bottom: var(--border-default);
    padding: 8px 0;
}

.accordion-item.finish_page {
    border-bottom: none;
}

.accordion-item.active .c-ctrl_header-accordion {
    background-color: var(--color-main-selected-blue);
}

.header-accordion_left-side p {
  @include text-body2;
}

.accordion-item.active .c-ctrl_header-accordion .header-accordion_left-side p {
    color: var(--color-main-blue);
}

.accordion-item .c-ctrl_header-accordion:hover {
    background-color: var(--color-main-light-gray);
    cursor: pointer;
}

.accordion-item.hidden .c-ctrl_header-accordion .header-accordion_left-side p {
    color: var(--color-main-gray);
}

.c-ctrl_footer {
    border-top: var(--border-default);
}


.constructor-body_nav_container .pagination-container,  
.constructor-body_display-header .pagination-container {
    background-color: var(--color-main-white);
    border: var(--border-default);
}

.pagination-container .paginate-buttons {
  @include text-body4;
  background-color: var(--color-main-white);
  color: var(--color-main-black);

}

.pagination-container .paginate-buttons:hover {
  background-color: var(--color-main-selected-blue);
}

.pagination-container .paginate-buttons.active-page {
  background-color: var(--color-main-selected-blue);
  color: var(--color-main-blue);
}

.adaptive_button_wrap {
  background-color: var(--color-main-white);
  // border: var(--border-default);
  box-shadow: 1px 1px 0 var(--color-main-light-blue) inset, -1px -1px 0 var(--color-main-light-blue) inset;
}

.constructor_adaptive button.active {
    border: var(--border-default);
    background-color: var(--color-main-selected-blue);
}

.adaptive_button_wrap button.desktop svg path {
    fill: var(--color-main-black);
}

.adaptive_button_wrap button.mobile svg path {
    fill: var(--color-main-black);
}
.adaptive_button_wrap button.desktop.active svg path {
    fill: var(--color-main-blue);
}

.adaptive_button_wrap button.mobile.active svg path {
    fill: var(--color-main-blue);
}

.hide_delete_step_button_wrap button.hide_step svg path {
    fill: var(--color-main-black);
}

// .hide_delete_step_button_wrap button.delete_step svg path {
//     fill: var(--color-main-black);
// }
.hide_delete_step_button_wrap button.hide_step.hidden svg path {
    fill: var(--color-main-blue);
}

.constructor_adaptive button:hover svg path {
    fill: var(--color-main-blue);
}

.save_quiz_data,
.add_section_button,
.get_link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_benefit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}




.c-ctrl-section {
  border-bottom: var(--border-default);
}
.c-ctrl-section.c-ctrl-section_last {
    border: none;
}
.c-ctrl-sf_format input:checked+label {
    border: var(--border-blue);
}
.c-ctrl-sf_media+label {
  border-color: var(--color-main-gray);
  background-color: var(--color-main-light-gray);
}

.all_pos_photo {
  border: var(--border-default);
}
.img_fit {
  border: var(--border-default);
}
.c-ctrl-sf_item-wrap textarea {
    @include default_textarea;
}
.c-ctrl-sf_item-wrap input[type=text],
.c-ctrl-sf_item-wrap input[type=number] {
    @include default_input;
}
.c-ctrl-sf_item-wrap input[type=text]::placeholder,
.c-ctrl-sf_item-wrap input[type=number]::placeholder,
.c-ctrl-sf_item-wrap textarea::placeholder {
  color: var(--color-main-dark-gray);
}
.c-ctrl-reset {
  color: var(--color-main-blue);
}
.c-ctrl-reset:disabled {
  color: var(--color-main-dark-gray);
}
.slider {
  background-color: var(--color-main-dark-gray);
}

.slider:before {
  background-color: var(--color-main-white);
}

input:checked + .slider {
  background-color: var(--color-main-blue);
}

input:checked + .slider:before {
  background-color: var(--color-main-white);
}
.c-ctrl-sf_bonus p {
  @include text-body5;
}
.c-ctrl-sf_bonus-img+label.add_media_filler {
    border-color: var(--color-main-gray);
}

.c-ctrl-sa_item-wrap input[type=text],
.c-ctrl-sa_item-wrap input[type=number] {
    @include default_input;
}

.c-ctrl-sa_item-wrap textarea {
    @include default_textarea;
}

.c-ctrl-sa_a-list .handle_answer {
  border: var(--border-default);
}

.answer-img_filler {
    border-color: var(--color-main-gray)
}

.c-ctrl-sform_item-wrap input[type=text],
.c-ctrl-sform_item-wrap input[type=tel],
.c-ctrl-sform_item-wrap input[type=email] {
    @include default_input;
}

.c-ctrl-sform_item-wrap textarea {
    @include default_textarea;
}

.label_contact_input_available {
  @include label_text_on;
}

.label_contact_input_disabled {
  @include label_text_off;
}

.slide_form_checkboxes label.slide_form_checkbox_text {
  @include text-body6;
  color: var(--color-main-dark-gray);
}

.slide_form_checkboxes label.slide_form_checkbox_text a {
  @include text-body6;
  padding: 0 4px;
  display: contents;
}

.slide_form_checkboxes label.slide_form_checkbox_text span.required_mark {
  display: contents;
}

.all_or_any_logic_label {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 16px;
  @include text-body5;
}

.question-logic_modal .all_or_any_logic_label input {
  display: none;
}

.all_or_any_logic_label input:checked~.checkbox_mark:after {
    display: block;
}
.all_or_any_logic_label .checkbox_mark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border-radius: 1px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.question-logic_modal label.all_or_any_logic_label span.checkbox_mark {
  display: contents;
  position: relative;
    display: block;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    border: 1px solid var(--color-main-black);
    background-color: #fff;
    border-radius: 3px;
    margin-right: 8px;
}

.slide_form_checkbox_text .checkbox_mark:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border-radius: 1px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    content: "";
    position: absolute;
    display: none;
}

.question-logic_modal .all_or_any_logic_label input:checked~.checkbox_mark {
    background-color: var(--color-main-blue);
    border-color: var(--color-main-blue);
}

.question-logic_modal .all_or_any_logic_label:hover input~.checkbox_mark {
    position: relative;
    background-color: var(--color-main-light-blue);
    border-color: var(--color-main-light-blue);
}

.c-ctrl-sform_contact_input_wrap {
    border-bottom: var(--border-default);
}

.vue-tel-input {
    @include default_input;
}

.c-ctrl-sl_item-wrap textarea {
    @include default_textarea;
}

.c-ctrl-sl_item-wrap input[type=text],
.c-ctrl-sl_item-wrap input[type=number] {
    @include default_input;
}







.c-ctrl-section.c-ctrl-s_colors {
  border-bottom: none;
}

.c-ctrl-s_colors .c-ctrl-s_item-wrap .name_theme_group {
    @include text-body5;
    color: var(--color-main-black);
}


.c-ctrl-s_colors ul li:hover,
.c-ctrl-s_colors ul li.active,
.c-ctrl-s_colors ul li input:checked+label,
.theme-colors_input:checked+label {
    cursor: pointer;
    background: var(--color-main-selected-blue);
}


.c-ctrl-s_colors ul li:hover .name_theme_group,
.c-ctrl-s_colors ul li.active .name_theme_group,
.c-ctrl-s_colors ul li input:checked~label .name_theme_group {
    cursor: pointer;
    color: var(--color-main-blue);
}

.c-ctrl-s_form h5 {
    border-top: var(--border-default);
}

.c-ctrl-s_form-wrap {
    background: var(--color-main-light-gray);
}

.c-ctrl-s_form-wrap>label label {
  border-color: transparent;
  color: var(--color-main-black);
}

.c-ctrl-s_form-wrap>label input:checked+label {
  border-color: var(--color-main-blue);
  color: var(--color-main-blue);
}

.c-ctrl-s_form-wrap>label .corner_form ~ .c-ctrl-s_item-wrap h6 {
  color: var(--color-main-black);
}

.c-ctrl-s_form-wrap>label .corner_form:checked ~ .c-ctrl-s_item-wrap h6 {
  color: var(--color-main-blue);
}

.c-ctrl-s_form-wrap .corner_form ~ .c-ctrl-s_item-wrap span {
  border-color: var(--color-main-black);
}

.c-ctrl-s_form-wrap .corner_form:checked ~ .c-ctrl-s_item-wrap span {
  border-color: var(--color-main-blue);
}

.c-ctrl-s_form-wrap .c-ctrl-s_item-wrap.active h6 {
  color: var(--color-main-blue);
}





.select_embed_variant {
    background: var(--color-main-white);
}
.select_embed_variant .variant_item p {
  @include text-body2;
}

.select_embed_variant .variants_wrap a.variant_item p {
  @include text-body2;
  color: var(--color-main-black);
}


.add_section_variant {
    background: var(--color-main-white);
}
.add_section_variant .variant_item p {
  @include text-body2;
}

.add_section_variant .variants_wrap a.variant_item p {
  @include text-body2;
  color: var(--color-main-black);
}

.integration_point button {
  @include text-body2;
  color: var(--color-main-black);
}
.integration_point.inactive_integration button {
  color: var(--color-main-dark-gray);
}
.integration_point button:hover,
.integration_point.active_integration button {
  color: var(--color-main-black);
}
.integration_point span.active_integration {
    @include text-body5;
    color: var(--color-main-blue);
    border: 1px solid var(--color-main-blue);
    background-color: var(--color-main-selected-blue);
}
.seting_tip_wrap p {
  @include text-body4;
  color: var(--color-main-dark-gray);
}
.settings_point_integration label,
.settings_advanced-settings button {
  @include text-body2;
  color: var(--color-main-black);
}

.integration_display_wrap,
.aditional_settings_wrap {
    border: var(--border-default);
    background: var(--color-main-white);
}

.integration_display_about,
.setting-point_display_about {
  @include text-body1;
  color: var(--color-main-blue);
}

.detail_instruction_wrap a,
.detail_instruction_wrap label {
  background-color: var(--color-main-light-blue);
  color: var(--color-main-blue);
}

.integration_about_text,
.setting-point_about_text {
  @include text-body4;
  color: var(--color-main-dark-gray);
}

.integration_display_input_wrap p,
.setting_display_input_wrap p {
    @include text-body5;
  color: var(--color-main-black);
}

.setting_display_input_wrap p.label_pro-only {
  border: 1px solid var(--color-main-blue);
  color: var(--color-main-blue);
  background-color: var(--color-main-selected-blue);
}

.setting_display_input_wrap input[type=text],
.setting_display_input_wrap input[type=number] {
  @include default_input;
}

.sub_settings_point input[type=text] {
  @include default_input;
}

.sub_settings_point_wrap .vidget-code_point textarea,
.sub_settings_point_wrap .vidget-code_point pre {
    @include pre;
    overflow: hidden;
}

.vidget-popup_setting_input input,
.vidget-button_setting_input input {
  @include default_input;
}

.vidget_poin p {
  @include text-body4;
  color: var(--color-main-dark-gray);
}

.one_user_link_wrap {
    border-top: var(--border-default);
}

.user-board_menu a {
    color: var(--color-main-black);
}
.user-board_menu a.router-link-active {
    background-color: var(--color-main-selected-blue);
    color: var(--color-main-blue);
}

.p-b_p-i_review_header {
  @include text-body3;
  color: var(--color-main-blue);
}
.b-p-p-board_prof-info {
  background-color: var(--color-main-white);
  border: var(--border-default);
}
.p-b_wrap-item-wrap_pagination {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.p-b_wrap-item-wrap_pagination p {
  @include text-body5;
}
.p-b_p-i_review_paragraph {
  @include text-body6;
  color: var(--color-main-dark-gray);
}
.p-b_input-wrap label {
    @include text-body5;
}
.p-b_input-wrap .p-b_profile_input, 
.p-b_input-wrap .p-b_profile_select{
    @include default_input;
}
.p-b_p-i_promo input[type="text"] {
  width: 100%;
    @include default_input;
}
.non_bill_wrap p {
  @include text-body4;
  color: var(--color-main-dark-gray);
}
.b-p_one_section.open h3{
  color: var(--color-main-blue);
}
.b-p_point-label {
  @include text-body6;
  color: var(--color-main-dark-gray);
}
.t-billing_body {
  border: var(--border-default);
}
.t-billing_table-header {
    background-color: var(--color-main-dark-gray);
}
.t-billing_th p {
  @include text-body5;
    color: var(--color-main-white);
}
.t-billing_tr:nth-child(odd) {
    background-color: var(--color-main-white);
}
.t-billing_tr:nth-child(even) {
    background-color: var(--color-main-light-gray);
}
.payment-modal-body {
  background-color: var(--color-main-white);
}
.save_current_discount span {
  border: var(--border-blue);
  border-radius: 100px;
  padding: 8px 12px;
  background-color: var(--color-main-selected-blue);
  color: var(--color-main-blue);
}
.payment_m_b h3.payment_m_b_description {
    color: var(--color-main-dark-gray);
}
.tariff_comparison_item {
  height: auto;
  border: var(--border-gray);
  border-color: var(--color-main-light-blue);
}
.tariff_comparison_item p {
  @include text-body3;
}
.tariff_comparison_item_current {
  background-color: var(--color-main-light-gray);
}
.tariff_comparison_item_current h3,
.tariff_comparison_item_current p {
  color: var(--color-main-black);
}
.tariff_comparison_item_current label {
  @include text-body6;
  color: var(--color-main-dark-gray);
}
.tariff_comparison_item_current label.payment_plan_name_label {
  @include text-body6;
  color: var(--color-main-black);
}

.tariff_comparison_item_future {
  background-color: var(--color-main-selected-blue);
  width: calc(50% - 12px);
  border-radius: 8px;
  overflow: hidden;
}
.tariff_comparison_item_future .tariff_comparison_item {
  width: 100%;
}
.tariff_comparison_item_future h3,
.tariff_comparison_item_future p {
  color: var(--color-main-blue);
}
.tariff_comparison_item_future label {
  @include text-body6;
  color: var(--color-main-black);
}
.tariff_comparison_item_future label.payment_plan_name_label {
  @include text-body6;
  color: var(--color-main-blue);
}
.tariff_comparisom_discount_wrap {
  padding: 8px 16px;
  background-color: var(--color-main-blue);
}
.tariff_comparisom_discount_wrap p {
  @include text-body6;
  text-align: center;
  color: var(--color-main-white);
}
.vue-slider-dot-tooltip-top {
  padding: 4px 16px;
  border-radius: 8px;
}
.tarifs_input_wrap .vue-slider-dot-tooltip-top {
  padding: 4px;
}
.corners_square .vue-slider-dot-tooltip-top {
  border-radius: 0;
}
.corners_square .vue-slider-dot {
  border-radius: 0;
  width: 16px!important;
  height: 16px !important;
}
.corners_square .vue-slider-dot-handle {
  border-radius: 0;
}
.vue-slider-dot-handle {
  border-width: 4px;
  border-style: solid;
}
.vue-slider-rail {
    background-color: rgba(0, 0, 0, 0.1)!important;
}
.corners_square .vue-slider-rail {
    border-radius: 0;
}
.tarifs_input_wrap .vue-slider-process {
    background-color: var(--color-main-gray);
}
.tarifs_input_wrap .vue-slider-dot-handle {
  background-color: var(--color-main-white);
  border-color: var(--color-main-blue);
}
.tarifs_input_wrap .vue-slider-mark-label {
  @include text-body2;
  color: var(--color-main-black);
}
.tarifs_input_wrap .vue-slider-mark-label-active {
  @include text-body1;
  color: var(--color-main-blue);
}
.tarifs_input_wrap .vue-slider-dot-tooltip-text {
  @include text-body5;
  color: var(--color-main-blue);
}
.tarifs_description {
  @include text-body6;
  color: var(--color-main-dark-gray);
}
.tarifs_description_important {
  @include text-body6;
  color: var(--color-main-black);
}
.tarifs_description_important span {
  @include text-body6;
  color: var(--color-main-blue);
}
.button_pay_for_tarif:hover {
    cursor: pointer;
}

.quiz-language-select {
  @include default_input_with_right_img;
}

.question_logic_setup_btn .conditions_counter {
  @include text-body7;
}

.question-logic_title {
    margin-bottom: 8px;
}

.question-logic_text_wrap p {
  @include text-body2;
  color: var(--color-main-dark-gray);
}

.question-logic_condition-title {
  @include text-body1;
  color: var(--color-main-blue)
}

.quiz-logic_item-select {
  @include default_input_with_right_img;
  width: 100%;
}

.input-domain_wrapper .input-label{
  @include text-body5;
}

.input-domain_label {
  @include default_input;
}

.input-domain_label .placeholder {
  color: var(--color-main-dark-gray);
}

.input-domain_label input {
  color: var(--color-main-black);
}

.domain_already_taken {
  color: var(--color-additional-red);
}

.trashcan_important {
    color: var(--color-main-blue);
    @include text-body1;
    padding-right: 8px;
}

.trashcan_two_sides p {
    display: inline-block;
    @include text-body2;
    width: calc(100% - 105px - 24px);
    color: var(--color-main-dark-gray);
}
.trashcan_important {
    color: var(--color-main-blue);
    @include text-body1;
}

.trashcan_delete-all {
  @include text-body5;
  color: var(--color-additional-red);
  border: var(--border-default);
  background-color: var(--color-main-white);
    border-radius: 8px;
    width: 105px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 16px;
    cursor: pointer;
}

.quiz-slot_foter_main-action .qs_restore-button p {
  @include text-body3;
  margin-left: 8px;
    color: var(--color-main-blue);
  }


.aqmai_quiz-title {
  @include text-body1;
  color: var(--color-main-blue);
}
.aqmai_quiz-leads-ansvers_label {
  @include text-body6;
  color: var(--color-main-dark-gray);
}
.aqmai_quiz-leads-ansvers_counter {
  @include text-body5;
  color: var(--color-main-black);
}
.resend_leads {
    padding: 8px 16px;
    height: auto;
    width: auto;
    font-size: 12px;
}
.small-font {
    @include text-body6;
  color: var(--color-main-dark-gray);
}
.open .bold-font_when_show {
  font-weight: 700;
}

.payment_plan_prices_wrap p span {
    @include text-body4;
    color: var(--color-main-dark-gray);
}


@media screen and (max-width: 442px) {
  .b-p_points_body {
      gap: 8px;
  }
  .b-p_point-wrap {
      width: calc(50% - 4px);
  }
  .trashcan_two_sides p {
      width: 100%;
  }
  .c-ctrl-s_form-wrap>label label {
    padding: 8px;
  }
}

.integrations_back-to-settings {
    display: none;
}

@media screen and (max-width: 650px) {
  .integrations_back-to-settings {
      display: block;
  }
}










// -----------------------------


.theme_1_white {
  .vue-slider-dot-handle {
    border-color: var(--theme-color-elem-bg-1);
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-1);
  }
  .slide_form_checkbox_text .checkbox_mark {
    background-color: var(--color-main-light-blue);
  }
  .slide_form_checkbox_text:hover input~.checkbox_mark {
    background-color: var(--color-main-light-gray);
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-1);
    border-color: var(--theme-color-elem-bg-1);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-1);
  }
}
.theme_1 {
  .vue-slider-dot-handle {
    border-color: var(--theme-color-elem-bg-1);
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-1);
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-1);
    border-color: var(--theme-color-elem-bg-1);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-1);
  }
}
.theme_2 {
  .vue-slider-dot-handle {
    background-color: #e6e6e6;
    border-color: var(--theme-color-elem-bg-2);
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-2);
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-2);
    border-color: var(--theme-color-elem-bg-2);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-2);
  }
}
.theme_3 {
  .vue-slider-dot-handle {
    background-color: #e6e6e6;
    border-color: var(--theme-color-elem-bg-3);
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-3);
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-3);
    border-color: var(--theme-color-elem-bg-3);
  }
}
.theme_4 {
  .vue-slider-dot-handle {
    background-color: #e6e6e6;
    border-color: var(--theme-color-elem-bg-4);
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-4);
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-4);
    border-color: var(--theme-color-elem-bg-4);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-4);
  }
}
.theme_5 {
  .vue-slider-dot-handle {
    background-color: #21252a;
    border-color: var(--theme-color-elem-bg-5);
  }
  .vue-slider-rail {
    background-color: rgba(255, 255, 255, 0.1)!important;
  }
  .vue-slider-dot-tooltip-top {
    border: 1px solid var(--theme-color-elem-bg-5);
    background: transparent;
  }
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-5);
  }
  .vue-slider-mark-label {
    color: #fff;
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: rgba(255, 255, 255, 0.1)!important;
    border-color: rgba(255, 255, 255, 0.1)!important;
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-5);
  }
}
.theme_6 {
  .vue-slider-dot-handle {
    border-color: var(--theme-color-elem-bg-6);
  }
  .vue-slider-rail {
    background-color: rgba(255, 255, 255, 0.1)!important;
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-6);
  }
  .vue-slider-mark-label {
    color: #fff;
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-6);
    border-color: var(--theme-color-elem-bg-6);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-6);
  }
}
.theme_7 {
  .vue-slider-dot-handle {
    border-color: var(--theme-color-elem-bg-7);
  }
  .vue-slider-rail {
    background-color: rgba(255, 255, 255, 0.1)!important;
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-7);
  }
  .vue-slider-mark-label {
    color: #fff;
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-7);
    border-color: var(--theme-color-elem-bg-7);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-7);
  }
}
.theme_8 {
  .vue-slider-dot-handle {
    border-color: var(--theme-color-elem-bg-8);
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-8);
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-8);
    border-color: var(--theme-color-elem-bg-8);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-8);
  }
}
.theme_9 {
  .vue-slider-dot-handle {
    border-color: var(--theme-color-elem-bg-9);
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-9);
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-9);
    border-color: var(--theme-color-elem-bg-9);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-9);
  }
}
.theme_10 {
  .vue-slider-dot-handle {
    border-color: var(--theme-color-elem-bg-10);
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-10);
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-10);
    border-color: var(--theme-color-elem-bg-10);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-10);
  }
}
.theme_11 {
  .vue-slider-dot-handle {
    border-color: var(--theme-color-elem-bg-11);
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-11);
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-11);
    border-color: var(--theme-color-elem-bg-11);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-11);
  }
}
.theme_12 {
  .vue-slider-dot-handle {
    border-color: var(--theme-color-elem-bg-12);
  }
  .vue-slider-rail {
    background-color: rgba(255, 255, 255, 0.2)!important;
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-12);
  }
  .vue-slider-mark-label {
    color: #fff;
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-12);
    border-color: var(--theme-color-elem-bg-12);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-12);
  }
}
.theme_13 {
  .vue-slider-dot-handle {
    border-color: var(--theme-color-elem-bg-13);
  }
  .vue-slider-rail {
    background-color: rgba(255, 255, 255, 0.2)!important;
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-13);
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-13);
    border-color: var(--theme-color-elem-bg-13);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text {
    color: #000;
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-13);
  }
}
.theme_14 {
  .vue-slider-dot-handle {
    border-color: var(--theme-color-elem-bg-14);
  }
  .vue-slider-rail {
    background-color: rgba(255, 255, 255, 0.3)!important;
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-14);
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-14);
    border-color: var(--theme-color-elem-bg-14);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-14);
  }
}
.theme_15 {
  .vue-slider-dot-handle {
    border-color: var(--theme-color-elem-bg-15);
  }
  .vue-slider-rail {
    background-color: rgba(255, 255, 255, 0.2)!important;
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-15);
  }
  .vue-slider-mark-label {
    color: #fff;
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color:  rgba(255, 255, 255, 0.2)!important;
    border-color:  rgba(255, 255, 255, 0.2)!important;
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--color-main-white);
  }
}
.theme_16 {
  .vue-slider-dot-handle {
    border-color: var(--theme-color-elem-bg-16);
  }
  .vue-slider-rail {
    background-color: rgba(255, 255, 255, 0.2)!important;
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-16);
  }
  .vue-slider-mark-label {
    color: #fff;
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-16);
    border-color: var(--theme-color-elem-bg-16);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--theme-color-elem-bg-16);
  }
}
.theme_17 {
  .vue-slider-dot-handle {
    border-color: var(--theme-color-elem-bg-17);
  }
  .vue-slider-rail {
    background-color: rgba(255, 255, 255, 0.2)!important;
  }
  .vue-slider-dot-tooltip-top,
  .vue-slider-process {
      background-color: var(--theme-color-elem-bg-17);
  }
  .vue-slider-mark-label {
    color: #fff;
  }
  .slide_form_checkbox_text input:checked ~ .checkbox_mark {
    background-color: var(--theme-color-elem-bg-17);
    border-color: var(--theme-color-elem-bg-17);
  }
  .slide_form_checkboxes label.slide_form_checkbox_text a {
    color: var(--color-main-white);
  }
}

.theme_custom.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {
    background: linear-gradient(90deg,var(--theme-color-custom-secondary),transparent);
}
.theme_custom.step-first-slide-format_row.full_bg .block_img .bg_darker {
    background: linear-gradient(270deg,var(--theme-color-custom-secondary),transparent);
}
.theme_custom.step-first-slide-format_blank.full_bg .block_img .bg_darker,
.theme_custom.step-first-slide.full_bg.mobile .block_img .bg_darker {
    background: linear-gradient(180deg,var(--theme-color-custom-secondary),transparent);
}
.theme_custom {
  background-color: var(--theme-color-custom-secondary)!important;

  .start,
  .step_forvard,
  .step_send,
  .variant input:checked+.answer_wrap,
  .vue-slider-dot-handle,
  .quiz_nav a,
  .quiz_nav input[type=button],
  .step-finish_go-to-url {
    border-color: var(--theme-color-custom-primary)!important;
  }
  .start,
  .step_send,
  .progresbar_line,
  .step_forvard,
  .ps__thumb-y,
  .vue-slider-process,
  .vue-slider-dot-tooltip-top,
  .slide_form_checkbox_text .checkbox_mark,
  .quiz_nav a,
  .quiz_nav input[type=button],
  .step-finish_go-to-url {
    background-color: var(--theme-color-custom-primary)!important;
  }
  .answer_wrap:focus,
  .answer_wrap:hover {
    box-shadow: 1px 3px 5px 0 var(--theme-color-custom-primary)!important;
  }
  .variant input:checked+.answer_wrap>div label+.answer-text,
  .variant input:checked+.answer_wrap>div label+.custom-answer,
  .slide_form_checkboxes label.slide_form_checkbox_text a,
  .slide_footer .footer-links a span,
  .footer-links a span, 
  .footer-links a, 
  .footer-links a+a {
    color: var(--theme-color-custom-primary)!important;
  }
  .variant input:checked+.answer_wrap {
    background-color: var(--theme-color-custom-secondary-lightened)!important;
  }
  .block_info h1,
  .bonus-block p,
  .block_info_body>p,
  .slide_description,
  .slide_title,
  .slide_answers_variant .answer_wrap>div label+.answer-text,
  .slide_answers_list .answer_wrap>div label+.answer-text,
  .slide_answers_variant .answer_wrap>div label+.custom-answer,
  .slide_answers_list .answer_wrap>div label+.custom-answer,
  .vue-slider-mark-label {
    color: var(--theme-color-custom-additional)!important;
  }
  .slide_social-links path {
    fill: var(--theme-color-custom-additional)!important;
  }
  .start,
  .vue-slider-dot-tooltip-text,
  .quiz_nav input[type=button],
  .quiz_nav a,
  .quiz_nav button,
  .quiz_nav a path,
  .quiz_nav button path,
  .step_forvard,
  .step_send,
  .step-finish_go-to-url {
    color: var(--theme-color-custom-button-text)!important;
    fill: var(--theme-color-custom-button-text)!important;
  }
  .slide_form_checkbox_text .checkbox_mark:after {
    border-color: var(--theme-color-custom-button-text)!important;
  }
  .vue-slider-dot-handle {
    background-color: var(--theme-color-custom-button-text)!important;
  }
}
.theme_custom.dark {
  .answer_wrap,
  .bonus-block,
  .benefit_wrap {
    border-color: rgba(255,255,255,0.2)!important;
  }
  .slide_progresbar,
  .vue-slider-rail,
  .ps__rail-y {
    background-color: rgba(255,255,255,0.2)!important;
  }
  input.answer-text.custom-answer::placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
  }
  .quiz_nav button.step_back path {
    color: rgba(255,255,255,0.2)!important;
    fill: rgba(255,255,255,0.2)!important;
  }
  .terms-and-policy,
  .slide_form_checkbox_text {
    color: rgba(255, 255, 255, 0.4) !important;
  }
}
// .theme_custom.dark.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {
//   background: linear-gradient(90deg,#272626,transparent);
// }
// .theme_custom.dark.step-first-slide-format_row.full_bg .block_img .bg_darker {
//     background: linear-gradient(270deg,#272626,transparent);
// }

.theme_custom.light {
  .answer_wrap,
  .bonus-block,
  .benefit_wrap {
    border-color: rgba(0,0,0,0.3)!important;
  }
  .slide_progresbar,
  .vue-slider-rail,
  .ps__rail-y {
    background-color: rgba(0,0,0,0.3)!important;
  }
  input.answer-text.custom-answer::placeholder {
    color: rgba(0, 0, 0, 0.4) !important;
  }
  .quiz_nav button.step_back path {
    color: rgba(0,0,0,0.3)!important;
    fill: rgba(0,0,0,0.3)!important;
  }
  .terms-and-policy,
  .slide_form_checkbox_text {
    color: rgba(0, 0, 0, 0.4) !important;
  }
}
// .theme_custom.light.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {
//     background: linear-gradient(90deg,hsla(0,0%,100%,.9) 45.89%,hsla(0,0%,100%,.18));
// }
// .theme_custom.light.step-first-slide-format_row.full_bg .block_img .bg_darker {
//     background: linear-gradient(270deg,hsla(0,0%,100%,.9) 43.44%,hsla(0,0%,100%,.18));
// }






</style>
