<template>
    <span class="dev_pan">
      DEV SERVER
    </span>
</template>
  
<script>
</script>
  
<style scoped>
  .dev_pan {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: black;
    color: chartreuse;
    font-weight: 700;
    padding: 3px;
  }
</style>
  